import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from './NewsCarousel.module.css';

const newsItems = [
  {
    title: 'lilly review',
    description: '',
    url: 'https://www.dreadcentral.com/reviews/514266/lilly-lives-alone-brooklyn-horror-2024-review-a-dreamy-dizzy-masterpiece/',
    image: 'https://benjamincleek.s3.us-west-2.amazonaws.com/photos/fadd30a4-b121-4596-b704-5a0f79cc4061-lilly%20review%20news%20card%20carosel.jpg',
  },
  {
    title: 'Damp January',
    description: '',
    url: 'https://pod.link/199024793/episode/7409d1322571b3684f2bd8bd4590b573',
    image: 'https://benjamincleek.s3.us-west-2.amazonaws.com/photos/667e506e-0815-47b0-becd-e1f3cacb3c2b-Screen%20Shot%202024-12-29%20at%206.45.09%20PM.png',
  },
  {
    title: 'lillyy afm sales',
    description: '',
    url: 'https://www.screendaily.com/news/dark-sky-acquires-lilly-lives-alone-for-north-america-yellow-veil-handling-afm-sales-exclusive/5198640.article',
    image: 'https://benjamincleek.s3.us-west-2.amazonaws.com/photos/1f68b590-851d-48f9-bcb3-e4a9ab64e0b4-Screen%20Shot%202024-12-29%20at%203.41.03%20PM.png',
  },
  {
    title: 'David Van Auken review',
    description: '',
    url: 'https://amplifythenoise.com/review-american-harmony-by-david-van-auken/',
    image: 'https://benjamincleek.s3.us-west-2.amazonaws.com/photos/343e94ba-38a5-487d-8c3c-ab3717a67d4f-Screen%20Shot%202024-12-29%20at%204.23.40%20PM.png',
  },



];

const NewsCarousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 4000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    arrows: true, 
    centerMode: true,
    swipe: true, 
    draggable: true,
  };

  return (
      <div className={styles.carouselContainer}>
      <Slider {...settings}>
        {newsItems.map((item, index) => (
          <div key={index} className={styles.slide}>
            {item.image && (
              <a
                href={item.url}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.link}
              >
                <img
                  src={item.image}
                  alt={item.title}
                  className={styles.image}
                />
              </a>
            )}
            {/* <h2 className={styles.title}>{item.title}</h2> */}
          </div>
        ))}
      </Slider>
    </div>

  );
};

export default NewsCarousel;