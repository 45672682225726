import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import styles from './albumPage.module.css';
import AlbumPageTrackCard from './AlbumPageTrackCard';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { API_ENDPOINT } from '../apiEndpoints';
import { IoIosArrowBack } from "react-icons/io";

const AlbumPage = ({ artistid, isEmbedded = false }) => {
  const [trackData, setTrackData] = useState([]);
  const [selectedTrack, setSelectedTrack] = useState('');
  const audioPlayerRef = useRef(null);
  const [currentTitle, setCurrentTitle] = useState('');
  const { albumid } = useParams();
  const navigate = useNavigate();
  const [currentTrackIndex, setCurrentTrackIndex] = useState(0);
  const [album, setAlbum] = useState({
    id: '',
    fileurl: '',
    albumname: '',
    artistname: '',
    releasedate: '',
    trackids: '',
    genres: '',
    tags: '',
    credits: '',
  });

  const getTrackNumber = (index) => index + 1;

  useEffect(() => {
    if (!isEmbedded) {
      window.scrollTo(0, 0);
    }
    const albumUrl = `${API_ENDPOINT}/albums/${albumid}`;
    
    axios.get(albumUrl)
      .then(({ data }) => {
        setAlbum(data);
        const trackidRegex = /"(\d+)"/g;
        const tracksString = data.trackids || ''; 
        const parsedTrackids = Array.from(
          tracksString.matchAll(trackidRegex),
          (match) => parseInt(match[1])
        );
    
        const promises = parsedTrackids.map((trackid) => {
          const trackUrl = `${API_ENDPOINT}/tracks/${trackid}`;
          return axios.get(trackUrl);
        });
    
        Promise.all(promises)
          .then((responses) => {
            const trackDetails = responses.map((response, index) => ({
              ...response.data,
              index: index + 1,
            }));
            setTrackData(trackDetails);
            if (trackDetails.length > 0) {
              setSelectedTrack(trackDetails[0]?.location?.fileurl || '');
            }
          })
          .catch((error) => {
            console.log('Error fetching tracks:', error);
          });
      })
      .catch((error) => {
        console.log('Error fetching album:', error);
      });
  }, [albumid, isEmbedded]);

  useEffect(() => {
    if (audioPlayerRef.current && selectedTrack) {
      console.log('Playing track:', selectedTrack);
      audioPlayerRef.current.audio.current.src = selectedTrack;
      audioPlayerRef.current.audio.current.play().catch((error) => {
        console.log('Playback error:', error);
      });
    }
  }, [selectedTrack]);

  const handleSelectTrack = (location, title, index) => {
    setSelectedTrack(location.fileurl || location);
    setCurrentTrackIndex(index);
    setCurrentTitle(title);
  };

  const handleNextTrack = () => {
    const nextTrackIndex = currentTrackIndex + 1;

    if (nextTrackIndex < trackData.length) {
      const nextTrackLocation = trackData[nextTrackIndex]?.location;
      if (nextTrackLocation) {
        setCurrentTrackIndex(nextTrackIndex);
        setCurrentTitle(trackData[nextTrackIndex]?.title);
        setSelectedTrack(nextTrackLocation);
      } 
    }
  };

  if (isEmbedded) {
    return (
      <div>
        <div className={styles.tracks}>
          {trackData.map((track, index) => (
            <AlbumPageTrackCard
              key={track.id}
              track={track}
              artistid={track.artistid}
              albumid={track.albumid}
              title={track.title}
              plays={track.plays}
              time={track.time}
              trackNumber={getTrackNumber(index)}
              isSelected={selectedTrack === (track.location?.fileurl || track.location)}
              onSelectTrack={() => handleSelectTrack(track.location, track.title, index)}
            />
          ))}
        </div>
        <div className={styles.Playbar}>
          <AudioPlayer
            ref={audioPlayerRef}
            src={selectedTrack}
            className={styles.rhap_container}
            layout="horizontal-reverse"
            customAdditionalControls={[]}
            customVolumeControls={[]}
            showJumpControls={true}
            header={`${currentTitle}`}
            onEnded={handleNextTrack}
          />
        </div>
      </div>
    );
  }

  // Rest of your existing return statement for non-embedded view...
  return (
    <div>
      <div className={styles.album}>
        <div className={styles.header}>
          <Link to="/music" className={styles.navigationArrow}>
            <IoIosArrowBack />
          </Link>
        </div>
        <div className={styles.column1}>
          <div className={styles.albumCard}>
            <img src={album.fileurl} alt={album.albumname} className={styles.customizeAlbumPhoto} />
            <div className={styles.albumInfo}>
              <div className={styles.albumName}> 
                {album.albumname} 
              </div>
              <div className={styles.info}>
                <div className={styles.releasedate}>
                   {album.releasedate}
                </div>
                <div className={styles.description}>
                  {album.description} 
                </div>
              </div>
              <div className={styles.links}>             
                <div className={styles.linerNotes}>
                </div> 
              </div>
            </div>         
          </div>
        </div>
        <div className={styles.tracks}>
          {trackData.map((track, index) => (
            <AlbumPageTrackCard
              key={track.id}
              track={track}
              artistid={track.artistid}
              albumid={track.albumid}
              title={track.title}
              plays={track.plays}
              time={track.time}
              trackNumber={getTrackNumber(index)}
              isSelected={selectedTrack === (track.location?.fileurl || track.location)}
              onSelectTrack={() => handleSelectTrack(track.location, track.title, index)}
            />
          ))}
        </div>
      </div>
      <div className={styles.Playbar}>
        <AudioPlayer
          ref={audioPlayerRef}
          src={selectedTrack}
          className={styles.rhap_container}
          layout="horizontal-reverse"
          customAdditionalControls={[]}
          customVolumeControls={[]}
          showJumpControls={true}
          header={`${currentTitle}`}
          onEnded={handleNextTrack}
        />
      </div>
    </div>
  );
};

export default AlbumPage;