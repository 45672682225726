// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NewsCarousel_carouselContainer__6WAbF {
  display: inline-block;
  width: 60vw; 
  height: 30%;
  overflow: visible;
  position: relative;
  margin-top: -13vh;
}

.NewsCarousel_slick-list__u\\+lOy {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height:20vh;

}

.NewsCarousel_slick-slide__FMz-w {
  display: flex; 
  justify-content: center;
  align-items: center;

}

.NewsCarousel_slide__adEwY {
}

.NewsCarousel_link__3neRv {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.NewsCarousel_image__AM76L {
  width: 70%; 
  height: auto;
  margin: 10vw;
  border-radius: 8px;
  transition: transform 0.3s ease, box-shadow 0.3s ease, z-index 0.3s ease;
}

.NewsCarousel_image__AM76L:hover {
  transform: scale(2.5); 
  box-shadow: 0 20px 20px rgba(95, 95, 95, 0.2);
  z-index: 100000;
  bottom: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/NewsCarousel.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,WAAW;EACX,WAAW;EACX,iBAAiB;EACjB,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,WAAW;;AAEb;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;;AAErB;;AAEA;AACA;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,UAAU;EACV,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,wEAAwE;AAC1E;;AAEA;EACE,qBAAqB;EACrB,6CAA6C;EAC7C,eAAe;EACf,SAAS;AACX","sourcesContent":[".carouselContainer {\n  display: inline-block;\n  width: 60vw; \n  height: 30%;\n  overflow: visible;\n  position: relative;\n  margin-top: -13vh;\n}\n\n.slick-list {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  position: relative;\n  height:20vh;\n\n}\n\n.slick-slide {\n  display: flex; \n  justify-content: center;\n  align-items: center;\n\n}\n\n.slide {\n}\n\n.link {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  height: 100%;\n}\n\n.image {\n  width: 70%; \n  height: auto;\n  margin: 10vw;\n  border-radius: 8px;\n  transition: transform 0.3s ease, box-shadow 0.3s ease, z-index 0.3s ease;\n}\n\n.image:hover {\n  transform: scale(2.5); \n  box-shadow: 0 20px 20px rgba(95, 95, 95, 0.2);\n  z-index: 100000;\n  bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"carouselContainer": `NewsCarousel_carouselContainer__6WAbF`,
	"slick-list": `NewsCarousel_slick-list__u+lOy`,
	"slick-slide": `NewsCarousel_slick-slide__FMz-w`,
	"slide": `NewsCarousel_slide__adEwY`,
	"link": `NewsCarousel_link__3neRv`,
	"image": `NewsCarousel_image__AM76L`
};
export default ___CSS_LOADER_EXPORT___;
